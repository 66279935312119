
/*----Footer----*/
.home-footer {
  .footer-inner {
    background-color: #080a12;
    background-image: url('../../../content/images/footer-background.png');
    background-repeat: no-repeat;
    background-size: inherit;

    .footer-top {
      padding: 50px 0;

      .logo {
        text-align: center;

        img {
          width: 100px;
        }

        .school-name {
          margin-top: 10px;
          font-size: 20px;
          line-height: 1.2;
          color: white;
          font-weight: 800;
        }
      }

      .school-info {
        color: white;

        .title {
          color: white;
          font-weight: 600;
        }

        .phone-number {
          font-size: 14px;
          font-weight: 500;
          color: white;
        }

        .address-list {
          padding: 0;
          list-style: none;

          .address-item {
            color: white;
            font-size: 14px;
          }
        }
      }

      .social {
        color: white;

        .title {
          color: white;
        }

        .social-list {
          display: flex;
          font-size: 48px;
          list-style: none;

          li a {
            color: white;
            margin-right: 30px;
          }
        }
      }
    }

    .footer-bottom {
      border-top: 1px solid #f3f3f3;
      padding: 20px 0;
      color: white;

      .navs {
        display: flex;
        justify-content: space-between;
        list-style: none;

        li a {
          color: #a3abb6;
        }
      }
    }
  }
}
