
.pagination {
  overflow: hidden;
  border-radius: 0;
  font-size: 12px;

  .page-item {
    color: #909399;
    text-align: center;
    background-color: #fff;

    &.active {
      .page-link {
        background-color: var(--primary-color);
        color: #fff;
        border-color: var(--primary-color);
      }
    }

    &.disabled {
      .page-link {
        background-color: #e9ecef;
        border-color: #e9ecef;
      }
    }

    .page-link {
      color: #909399;
      border: 1px solid #e9ecef;
      margin-left: 0;
      font-weight: 500;
      border-radius: 0;
      min-width: 30px;
      min-height: 30px;
      padding: unset;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
